<template>
  <v-container fluid>
    
      <v-form ref="form" @submit.prevent="validateBeforeSubmit">
        <v-col cols="12">
          <v-text-field
            v-model="vm.privateHealthInsuranceDetails"
            label="Private Health Insurance Details"
            v-validate="'required'"
            data-vv-as="Health insurance"
            name="privateHealthInsuranceDetails"
            :error-messages="errors.collect('privateHealthInsuranceDetails')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.isSmoker"
            label="Is the participant a smoker?"
            v-validate="'required'"
            data-vv-as="Smoker"
            name="isSmoker"
            :error-messages="errors.collect('isSmoker')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="vm.hasSeizurePlan"
            label="Does the participant have a Seizure Plan or any other care plan?"
            v-validate="'required'"
            data-vv-as="Has seizure plan or other"
            name="hasSeizurePlan"
            :error-messages="errors.collect('hasSeizurePlan')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
      <v-col cols="12" v-if="vm.hasSeizurePlan == true">
      <v-text-field
            v-model="vm.planList"
            label="Please list Plans (and ensure they are uploaded via participant dashboard)"
            v-validate="'required'"
            data-vv-as="Plan List"
            name="planList"
            :error-messages="errors.collect('planList')"
          ></v-text-field>
      </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.doSpecialCareNursingRequired"
            label="Is special care nursing required?"
            v-validate="'required'"
            data-vv-as="Is special care nursing required "
            name="doSpecialCareNursingRequired"
            :error-messages="errors.collect('doSpecialCareNursingRequired')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="vm.nutritionSwalloingIssues"
            label="Does the participant have Nutrition/Swalloing Issues?"
            v-validate="'required'"
            data-vv-as="Nutrition swalloing issues"
            name="nutritionSwalloingIssues"
            :error-messages="errors.collect('nutritionSwalloingIssues')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="vm.tetanus"
            label="Does the participant have regular Tetanus injections?"
            v-validate="'required'"
            data-vv-as="Tetanus"
            name="tetanus"
            :error-messages="errors.collect('tetanus')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
            <v-radio label="Unknown" value="Unknown"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-if="vm.tetanus == true">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.tetanusDateLastReceived"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.tetanusDateLastReceived"
                label="When was the participants last tetanus injection?"
                v-on="on"
                v-validate="'required'"
                data-vv-as="tetanusDateLastReceived"
                readonly
                name="tetanusDateLastReceived"
                :error-messages="errors.collect('tetanusDateLastReceived')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="vm.tetanusDateLastReceived"
              no-title
              scrollable
              color="green lighten-1"
              dark
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(vm.tetanusDateLastReceived)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
       
          <v-radio-group
            v-model="vm.hepatitis"
            label="Has the participant had their hepatitis A injections?"
            v-validate="'required'"
            data-vv-as="Hepatitis"
            name="hepatitis"
            :error-messages="errors.collect('hepatitis')"
          >
            <v-radio label="Yes" v-bind:value="true"></v-radio>
            <v-radio label="No" v-bind:value="false"></v-radio>
            <v-radio label="Unknown" value="Unknown"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="vm.hepatitis == true">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.hepatitisDateLastReceived"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.hepatitisDateLastReceived"
                label="Date last received"
                v-on="on"
                readonly
                v-validate="'required'"
                data-vv-as="hepatitisDateLastReceived"
                name="hepatitisDateLastReceived"
                :error-messages="errors.collect('hepatitisDateLastReceived')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="vm.hepatitisDateLastReceived"
              no-title
              scrollable
              color="green lighten-1"
              dark
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(vm.hepatitisDateLastReceived)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" type="submit">Continue</v-btn>
      </v-form>
    
  </v-container>
</template>



<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "medical-form",
  data: () => ({
    vm: new Models.Medical(),
    menu: false,
    menu1: false,
    valid: false,
    selected: [],
    clientMedications: [
      "Allergies",
      "Blood Disorder",
      "Cerebral Palsy",
      "Diabetes",
      "Epilespy",
      "Heart Related e.g Blood Pressure",
      "Mental Health",
      "Respiratory",
      "Skeletal",
      "Syndrome",
      "Weight/Metabolic"
    ],
    rules: {
      hepatitis: value => {
       if(value === "true" || value === "false" || value === "Unknown")
        {return "" }else { return "The Hepatitis field is required"}}
       },
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.medical == undefined
          ? { clientMedications: [] }
          : this.$store.getters.currentCase.medical;

      if (
        this.vm.clientMedications == undefined ||
        this.vm.clientMedications == null
      ) {
        this.vm.clientMedications = [];
      }
      // this.vm.tetanusDateLastReceived = new Date().toISOString().substr(0, 10);
      // this.vm.hepatitisDateLastReceived = new Date().toISOString().substr(0, 10);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.medical = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
}
.v-input--checkbox {
  margin-top: 0px;
}
</style>

